import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dynamic", "revealed", "hidden", "input"];
  static values = {
    matches: String,
    equals: String,
  };

  connect() {
    this.element[this.identifier] = this;
    this.hiddenClass = this.data.get("class") || "hidden";
    this.show();
  }

  show(event) {
    if (event) event.preventDefault();

    if (this.hasInputTarget) {
      let inputValues;
      if (this.inputTargets.length > 0) {
        inputValues = this.inputTargets
          .filter((node) => node.checked)
          .map((node) => node.value);
      } else {
        inputValues = this.inputTargets.map((node) => node.value);
      }
      let matchingTargets = this.dynamicTargets.filter((target) => {
        if (target.dataset.visibilityMatchesValue !== undefined) {
          let matcher = new RegExp(target.dataset.visibilityMatchesValue);
          return inputValues.some((v) => matcher.test(v));
        }
        else if (target.dataset.visibilityEqualsValue !== undefined) {
          let matcher = target.dataset.visibilityEqualsValue;
          return inputValues.some((v) => matcher == v);
        } else {
          return inputValues.length > 0;
        }
      });

      this.#showTargets(matchingTargets)
    } else {
      this.#showTargets(this.dynamicTargets)
    }
  }

  #showTargets(targets) {
    this.dynamicTargets.forEach((target) => {
      if (target.dataset.visibilityDynamic == "revealed") {
        this._hideTarget(target);
      } else if (target.dataset.visibilityDynamic == "hidden") {
        this._showTarget(target);
      } else {
        this._hideTarget(target);
      }
    });

    targets.forEach((target) => {
      if (target.dataset.visibilityDynamic == "revealed") {
        this._showTarget(target);
      } else if (target.dataset.visibilityDynamic == "hidden") {
        this._hideTarget(target);
      } else {
        this._showTarget(target);
      }
    });
  }

  _hideTarget(target) {
    target.classList.add(this.hiddenClass);
    target.disabled = true;
    target.setAttribute("disabled", true);
  }

  _showTarget(target) {
    target.classList.remove(this.hiddenClass);
    target.disabled = false;
    target.removeAttribute("disabled");
  }
}
